import React, { useState } from "react";
import "./main.css";
import "./Login.css";
import CurrentYear from '../components/CurrentYear';

function Login() {
    const [username, setUsername] = useState("");
    const [password, setPassword] = useState("");

    const handleUsernameChange = (e) => {
        setUsername(e.target.value);
    };

    const handlePasswordChange = (e) => {
        setPassword(e.target.value);
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
          // Make an API call to authenticate the user's credentials
          const response = await fetch("/api/login", {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify({ username, password }),
          });
    
          if (response.ok) {
            // User is authenticated, perform necessary actions
            console.log("Login successful!");
          } else {
            // User authentication failed, handle the error
            console.log("Login failed!");
          }
        } catch (error) {
          console.log("An error occurred during login:", error);
        }
      };
    
    return (
<div className="main">
  {/* <img src="img/owl.png" alt="Owl" className="overlay-image" /> */}
  <div className="topbox">
    <p className="effteenet">efftee.net</p>
  </div>
  <div className="centerbox">
    <div className="container">
      <h2>Login</h2>
      <form onSubmit={handleSubmit}>
        <input
          type="text"
          id="username"
          name="username"
          placeholder="Username"
          value={username}
          onChange={handleUsernameChange}
        />
        <input
          type="password"
          id="password"
          name="password"
          placeholder="Password"
          value={password}
          onChange={handlePasswordChange}
        />
        <button type="submit">Login</button>
      </form>
    </div>
  </div>
  <div className="bottombox">
    <p className="footer">
    &#169; <CurrentYear /> efftee.net
    </p>
  </div>
</div>
    );
}

export default Login;
import { Route, Routes, Link } from "react-router-dom"
import Home from "./pages/Home"
import Login from "./pages/Login"
import "./pages/main.css";

function App() {
  return (
  <>
    <Link to="/" className="home">
      <img src="img/owl.png" alt="Owl" className="overlay-image" />
    </Link>
    <Routes>
      <Route path="/" element={<Home/>} />
      <Route path="/login" element={<Login/>} />
    </Routes>
  </>
  )
}

export default App
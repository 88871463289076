// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.container {
  width: 300px;
  display: grid;
  margin: auto;
}

form {
  display: grid;
  gap: 1rem;
}

button {
  border: none;
  border-radius: 5px;
  padding: 0.5rem;
  font-size: 20px;
  margin-top: 1rem;
  margin-bottom: 2rem;
  background-color: #4b575f;
  color: #dbdbdb;
  cursor: pointer;
  opacity: 1;
  font-family: "Poppins", sans-serif;
  font-size: 1.2rem;
  font-weight: 200;
}

button:hover {
  opacity: 0.8;
}

input {
  border: none;
  border-radius: 5px;
  padding: 0.5rem;
  /* font-size: 20px; */
  font-family: "Poppins", sans-serif;
  font-size: 1.2rem;
  font-weight: 200;
}

h2 {
  color: #4b575f;
  font-family: "Poppins", sans-serif;
  font-size: 2rem;
  font-weight: 200;
}`, "",{"version":3,"sources":["webpack://./src/pages/Login.css"],"names":[],"mappings":"AAAA;EACE,YAAY;EACZ,aAAa;EACb,YAAY;AACd;;AAEA;EACE,aAAa;EACb,SAAS;AACX;;AAEA;EACE,YAAY;EACZ,kBAAkB;EAClB,eAAe;EACf,eAAe;EACf,gBAAgB;EAChB,mBAAmB;EACnB,yBAAyB;EACzB,cAAc;EACd,eAAe;EACf,UAAU;EACV,kCAAkC;EAClC,iBAAiB;EACjB,gBAAgB;AAClB;;AAEA;EACE,YAAY;AACd;;AAEA;EACE,YAAY;EACZ,kBAAkB;EAClB,eAAe;EACf,qBAAqB;EACrB,kCAAkC;EAClC,iBAAiB;EACjB,gBAAgB;AAClB;;AAEA;EACE,cAAc;EACd,kCAAkC;EAClC,eAAe;EACf,gBAAgB;AAClB","sourcesContent":[".container {\n  width: 300px;\n  display: grid;\n  margin: auto;\n}\n\nform {\n  display: grid;\n  gap: 1rem;\n}\n\nbutton {\n  border: none;\n  border-radius: 5px;\n  padding: 0.5rem;\n  font-size: 20px;\n  margin-top: 1rem;\n  margin-bottom: 2rem;\n  background-color: #4b575f;\n  color: #dbdbdb;\n  cursor: pointer;\n  opacity: 1;\n  font-family: \"Poppins\", sans-serif;\n  font-size: 1.2rem;\n  font-weight: 200;\n}\n\nbutton:hover {\n  opacity: 0.8;\n}\n\ninput {\n  border: none;\n  border-radius: 5px;\n  padding: 0.5rem;\n  /* font-size: 20px; */\n  font-family: \"Poppins\", sans-serif;\n  font-size: 1.2rem;\n  font-weight: 200;\n}\n\nh2 {\n  color: #4b575f;\n  font-family: \"Poppins\", sans-serif;\n  font-size: 2rem;\n  font-weight: 200;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;

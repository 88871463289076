import "./main.css";
import { useState, useEffect } from "react";
import axios from "axios";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowRightToBracket } from '@fortawesome/free-solid-svg-icons';
import { Link } from 'react-router-dom';
import CurrentYear from '../components/CurrentYear';

function Home() {
  const [ip, setIP] = useState("");
  const [isAllowedIP, setIsAllowedIP] = useState(false);
  const getUserIP = async () => {
    const res = await axios.get("https://api.ipify.org/?format=json");
    setIP(res.data.ip);
    const allowedIPs = process.env.REACT_APP_ALLOWED_IPS.split(",");
    setIsAllowedIP(allowedIPs.includes(res.data.ip));
  };

  useEffect(() => {
    getUserIP();
  }, []);

  // Button Redirects
  const handleClickPhotography = () => {
    window.open("https://images.efftee.net", "_blank");
  };

  return (
    <div className="main">
        {isAllowedIP && (
            <div>
              <Link to="/login" className="loginbutton">
                <FontAwesomeIcon icon={faArrowRightToBracket} size='2x'/>
              </Link>
            </div>
        )}
      <div className="topbox">
        <p className="effteenet">efftee.net</p>
      </div>
      <div className="centerbox">
        <div className="textbox">
          <p className="welcome">Welcome !</p>
          <p className="paragraph">
            You have reached efftee.net
            <br />
            There ain't much to see here at the moment.
            <br />
            In the mean time, feel free to check out my:
          </p>
        </div>
        <button className="button" onClick={handleClickPhotography}>
          Photography
        </button>
      </div>
      <div className="bottombox">
        <p className="footer">
          &#169; <CurrentYear /> efftee.net
          <br />
          All connections monitored
          <br />
          Your ip: {ip}
        </p>
      </div>
    </div>
  );
}

export default Home;
